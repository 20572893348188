.music-button-styles{
    position: absolute;
    top: 10px;
    left: 106px;
    cursor: pointer;
}

.mute-button-container{
    position: relative;
}

.music-button-styles:active{
    transform: scale(1.1);
    transition: 0.2s all;
    cursor: pointer;
}

.music-button-styles img{

    width: 44.25px;
    height: 44.25px;

}

@media screen and (min-width: 577px){
    .music-button-styles{
        top: 25px;
        left: 230px;
    }
    
    .music-button-styles img{
    
        width: 44.25px;
        height: 44.25px;
    
    }

}

