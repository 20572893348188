.memory-board-container{
    background-image: url('../../assets/img/Bg_Memoryboard.jpg');
    background-repeat: repeat;
    min-height: 78vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.memory-card-container{
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    justify-items: center;
    place-content: center;
}

.custom-text-memory{
    display: flex;
    gap: 0.5rem;
}

.custom-text-memory img{
    width: 30px;
    height: 30px;
}

.custom-text-memory p{
    color: var(--white-smoke);
    font-weight: bold;
    font-size: 22px;
}

.custom-text-memory p span{
    color: var(--white-smoke);
    font-weight: 500;
    font-size: 22px;
    font-weight: 400;
}

.button-position{
    position: relative;
}

.text-hola-color{
    color: var(--white-smoke) !important;
}

@media screen and (min-width: 577px){

	.memory-card-container{
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem
    }

    .custom-text-memory{
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
}

@media screen and (min-width: 992px){
    .memory-board-container{
        min-height: 95vh;

    }

}