.image-container {
width: 270px;
height: 270px;
border-radius: 50%;
border: 20px solid var(--orange);
display: flex;
justify-content: center;
align-items: center;
}

.image-container img{
    width: 255px;
    height: 255px;
    border-radius: 50%;
}