.bg-credits {
  background-image: url(../../assets/img/Bg_parents.jpg);
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3%;
}

.description {
  font-size: 1rem;
  color: var(--purple-dark);
  font-weight: 500;
  width: 30rem;
}

.credits-sections {
    margin: 3rem;
  }

.icon-container {
  margin-left: 1rem;
}

.intro-credits {
    margin: 3rem;
  }

h6 {
  color: #661890;
  font-family: Roboto;
  font-weight: 900;
}



@media screen and (max-width: 577px) {
  .bg-credits {
    background-image: url("../../assets/img/Bg_parents.jpg");
    background-size: 70vh;
  }

  .credits-sections {
    margin: 2rem;
  }

  .intro-credits {
    margin: 6%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .description{
    margin-left: 0.8rem;
  }

  .icon-container {
    margin-left: 20%;
  }
}

@media screen and (min-width: 1024px) {
    .center-container {
        margin-left: 20%;
    }

}
