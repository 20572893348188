
.frontImage{
background: linear-gradient( to right, #FF730E, #FFB83E);

}

.backVideo, .frontImage{
    width: 170px;
    height: 170px;
    border-radius: 20px;
    font-weight: bold !important; 
    font-size: 6rem !important;
    box-shadow: rgba(47, 8, 106, 0.24) 0px 5px 8px;
}



