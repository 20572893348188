.image-btn{
    border: none;
    box-shadow: rgba(234, 234, 239, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    cursor: pointer;
    outline: none;
}

.image-btn:active{
    transform: scale(1.1);
    box-shadow: var(--orange) 0px 0px 0px 2px, rgba(101, 18, 34, 0.65) 0px 4px 6px -1px, rgba(247, 160, 160, 0.08) 0px 1px 0px inset;
    transition: 0.2s all;
}
.image-btn:hover{
    transform: scale(1.1);
    box-shadow: var(--orange) 0px 0px 0px 2px, rgba(101, 18, 34, 0.65) 0px 4px 6px -1px, rgba(247, 160, 160, 0.08) 0px 1px 0px inset;
    transition: 0.2s all;
}



.image-btn img, .image-btn{
    border-radius: 2rem;
}