form {
    max-width: 500px;
    margin: 0 auto;
}

label {
    margin-top: 10px;
    font-weight: bold;
}

.contact-input[type=text], .contact-input[type=email],  textarea {
    width: 100%;
    padding: 10px;
    float: left;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    color: #FFFFFF;
    background-color: #661890
}

.contact-input::placeholder {
    color: #FFFFFF;
}

textarea {
    height: 150px;
}

.contact-input[type="submit"] {
    background: linear-gradient(90deg, #6D17F2 26.61%, #D157FF 157.8%);
    border-radius: 10px;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100px;
    height: 40px;
    left: 262px;
    top: 496px;
    margin: 30px 0;
}

.contact-input[type="submit"]:hover {
    background-color: #45a049;
}

.form-title {
    font-size: 22px;
    margin: 0;
}

.form-container {
    background-image: url(../../assets/img/Bg_parents.jpg);
    padding-top: 35px;
    min-height: 85vh;
}

.form-container-right {
    padding: 30px;
}

.form-container-right p {
    color: #661890;
}

.form-container-left {
    padding-left: 34px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-container-title {
    color: #6D17F2;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 30px;
}

.star-icon {
    padding-right: 20px;
    margin-bottom: 8px;
}

.icon-social {
    padding-right: 10px;
}

.mail-text {
    padding-top: 34px;
}

.social-text {
    padding-top: 39px;
}

.social-icons {
    padding: 13px;
}

@media (max-width: 768px) { 
    .form-container-left {
       padding: 20px;
       display: flex;
       flex-direction: column;
    }

    .form-container-right h2{
    margin: 1.5rem;
     }

}

@media screen and (min-width: 1280px) {
    .form-container-left {
margin-left: 30%;
}
}